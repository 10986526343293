import React, { useState, useEffect } from 'react'
import { styled } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Typed from 'typed.js'
import { ContentWrapper, PageTitle, CardWrapper, GreenWrapper, LineTextLine, Footer } from '@/components'
import { Box, Image, Text, Grid } from '@/packages'
import { logo } from '@/assets/images/'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const Wrapper = styled(Box)`
  position: absolute;
  left: 50%;
  bottom: 140px;
  transform: translate(-50%, 50%);
`
const LogoWrapper = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`
const SLOGAN_LIST = ['Redefine AI supply chain', 'Task Decentralization', 'Explainable AI', 'Incentive Alignment']

export const Dashboard = () => {
  const navigate = useNavigate()
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [index, setIndex] = useState<number>(0)
  const el = React.useRef(null)
  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['Redefine AI supply chain', 'Task Decentralization', 'Explainable AI', 'Incentive Alignment'],
      typeSpeed: 100,
      loop: true,
      loopCount: Infinity
    })

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy()
    }
  }, [])

  return (
    <Box>
      <LogoWrapper className="center">
        <Box className="fx-col ai-ct ">
          <Image
            src={logo}
            height={isMobile || isTablet ? 200 : 300}
            width={isMobile || isTablet ? 200 : 300}
            style={{ marginTop: isMobile || isTablet ? '-90%' : '-20%' }}
          />

          <Box height="24px" className="">
            <Text
              ref={el}
              className="white"
              fontSize={['18px', '18px', '24px', '24px']}
              style={{ display: 'inline-block' }}
            />
          </Box>
        </Box>
      </LogoWrapper>
      <Wrapper className=" w100">
        <ContentWrapper>
          <Grid gridTemplateColumns={['1fr', '1fr', '1fr 1fr', '1fr 1fr']} className=" w100">
            <Box>
              <Text className=" fw500 white" fontSize={['24px', '24px', '32px', '32px']}>
                Redefine AI agent supply chain
              </Text>
              <Text className="gray mt24 " fontSize={['16px', '16px', '20px', '20px']}>
                We make the supply chain of explainable AI agents lucrative via GenAI and Evolutionary Game Theory
              </Text>
            </Box>
            <Box className=" fx-col jc-end" marginTop={['24px', '24px', '0', '0']}>
              <LineTextLine name="HELLO WORLD" />
              <CardWrapper
                className="center click mt12"
                bg="#fff"
                hover="rgb(203,203,203)"
                height="45px"
                onClick={() => navigate('/about')}
              >
                <Text className="black " fontSize={['14px', '14px', '16px', '16px']}>
                  What is Aimelia.network?
                </Text>
              </CardWrapper>
              <Grid className="mt12" gridTemplateColumns="2fr 1fr">
                <CardWrapper
                  className="center click"
                  height="45px"
                  onClick={() => window.open('https://events.predx.ai', '_blank')}
                >
                  <Text className="white " fontSize={['14px', '14px', '16px', '16px']}>
                    Launch a pilot
                  </Text>
                </CardWrapper>
                <CardWrapper className="center ml12 click" height="45px" onClick={() => navigate('/team')}>
                  <Text className="white" fontSize={['14px', '14px', '16px', '16px']}>
                    Team
                  </Text>
                </CardWrapper>
              </Grid>
            </Box>
          </Grid>
        </ContentWrapper>
      </Wrapper>
      {/* <Footer /> */}
    </Box>
  )
}
