import React, { useState, useEffect } from 'react'
import { styled } from 'styled-components'
import { Drawer } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { Text, Box, Image } from '@/packages'
import { logo, xWhite, discordWhite, github, close, menu } from '@/assets/images'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { Button, Row, Col, ContentWrapper } from '@/components'

const NavWrapper = styled(Box)`
  height: 120px;
`
const list = [
  {
    url: 'https://x.com/AimeliaNetwork',
    icon: xWhite
  },
  {
    url: 'https://discord.com/invite/predx-949853427771535370',
    icon: discordWhite
  },
  {
    url: 'https://github.com/Cointegrator',
    icon: github
  }
]
const IconWrapper = styled(Box)`
  height: 40px;
  width: 40px;
  border: 1px solid #ffffff1a;
  border-radius: 8px;
  &:hover {
    border: 1px solid #fff;
  }
`

export const LaunchNav = () => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const { isMobile, isTablet } = useMatchBreakpoints()
  const navigate = useNavigate()
  const NavTabs = [
    { url: '/whitepaper', name: 'WHITEPAPER' },
    { url: '/about', name: 'ABOUT' },
    { url: '/product', name: 'PRODUCT' },
    { url: '/writing', name: 'WRITING' },
    { url: '/team', name: 'TEAM' }
  ]
  const location = useLocation()

  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = document.documentElement.scrollTop || document.body.scrollTop
      setHeight(scrollY)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <ContentWrapper>
      <Box className={height > 64 ? 'scroll_nav ' : 'nav'} height="120px">
        <Box className="click fx-row ai-ct" onClick={() => navigate('/')}>
          <Image src={logo} className="w100 h100" alt="" height={66} width={66} />
          <Text className="white fw500" fontSize={['18px', '18px', '22px', '22px']}>
            Aimelia
          </Text>
        </Box>
        <Row className="">
          {isMobile || isTablet ? (
            <Box onClick={() => setOpen(true)} className="click menu" />
          ) : (
            <Row className="mr10">
              {NavTabs.map((item) => (
                <Text
                  key={item.name}
                  className="fz14 mr40 click"
                  fontWeight={item.url === location.pathname ? 800 : 700}
                  style={{ color: item.url === location.pathname ? '#fff' : '#808080' }}
                  onClick={() => {
                    if (item.url === '/whitepaper') {
                      window.open('https://whitepaper.aimelia.network/', '_blank')
                    } else {
                      navigate(item.url)
                    }
                  }}
                >
                  {item.name}
                </Text>
              ))}
              {list.map((item) => (
                <IconWrapper className="center click ml8" onClick={() => window.open(item.url, '_blank')}>
                  <Image src={item.icon} height={16} width={16} />
                </IconWrapper>
              ))}
            </Row>
          )}
        </Row>

        <Drawer
          onClose={() => setOpen(false)}
          autoFocus
          destroyOnClose
          footer={null}
          title={null}
          width="80%"
          placement="left"
          className="click"
          maskClosable
          style={{ background: 'rgb(13,13,13)' }} //  marginTop: '120px'
          open={isOpen}
          closeIcon={<Image src={close} height={16} width={16} className="click mt48" />}
        >
          <Col className="">
            {NavTabs.map((item) => (
              <Text
                key={item.name}
                className="fz14 click mt24"
                style={{ color: item.url === location.pathname ? '#fff' : '#808080' }}
                onClick={() => {
                  if (item.url === '/whitepaper') {
                    window.open('https://whitepaper.aimelia.network/', '_blank')
                  } else {
                    setOpen(false)
                    navigate(item.url)
                  }
                }}
              >
                {item.name}
              </Text>
            ))}
            <Row className="mt24">
              {list.map((item) => (
                <IconWrapper
                  className="center click"
                  onClick={() => {
                    setOpen(false)
                    window.open(item.url, '_blank')
                  }}
                >
                  <Image src={item.icon} height={16} width={16} />
                </IconWrapper>
              ))}
            </Row>
          </Col>
        </Drawer>
      </Box>
    </ContentWrapper>
  )
}
