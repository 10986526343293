import React, { useState } from 'react'
import { styled } from 'styled-components'
import { ContentWrapper, PageTitle, CardWrapper, Footer } from '@/components'
import { Box, Image, Text, Grid } from '@/packages'
import { ceo, cto, cmo, coo, cbo } from '@/assets/images/'

const list = [
  {
    title: 'Core',
    info: [
      {
        avatar: ceo,
        name: 'Rein Wu',
        job: 'Founder & CEO',
        desc: 'Research Lead @ Visa Research, Best CS PhD @ UH'
      },
      {
        avatar: cto,
        name: 'Jason Wong',
        job: 'Co-founder & CTO',
        desc: 'Sr. Researcher @ Qualcomm, CS PhD @ OSU'
      }
    ]
  },
  {
    title: 'Executive',
    info: [
      {
        avatar: cmo,
        name: 'Jeanne Huai',
        job: 'Marketing',
        desc: 'Trading KOL with 65K+ followers, M&A @ KPMG'
      },
      {
        avatar: cbo,
        name: 'Amelia Hu',
        job: 'Business',
        desc: 'Business Consult @ Efficio, ME @ MIT, Tsinghua'
      },
      {
        avatar: coo,
        name: 'Rocken Wu',
        job: 'Community',
        desc: 'City Manager @ Sanpower, Channel Dev Manager @ China Telecom Global'
      }
    ]
  }
]
const JobWrapper = styled(Box)`
  background: rgb(16, 21, 28);
  height: 32px;
  padding: 0 12px;
  margin-left: 12px;
`
export const Team = () => {
  return (
    <ContentWrapper>
      <PageTitle title="Team" subTitle="Builders behind Aimelia" />
      <Box className="" marginTop={['24px', '24px', '48px', '48px']}>
        {list.map((item, idx) => (
          <Box className=" w100">
            <Text
              className=" white fw500 mb42"
              marginTop={['36px', '36px', '86px', '86px']}
              fontSize={['18px', '18px', '24px', '24px']}
            >
              {item.title}
            </Text>
            <Grid className="fx-row wrap" gridTemplateColumns={['1fr', '1fr', '1fr 1fr', '1fr 1fr']}>
              {item.info.map((_item, index) => (
                <CardWrapper
                  bg="rgb(13,13,13)"
                  hover="rgb(26,26,26)"
                  // onClick={() => window.open('https://twitter.com/niraj', '_blank')}
                  className="fx-row ai-ct click"
                  paddingLeft={['12px', '12px', '24px', '24px']}
                  paddingRight={['12px', '12px', '24px', '24px']}
                  paddingTop={['12px', '12px', '24px', '24px']}
                  paddingBottom={['12px', '12px', '24px', '24px']}
                >
                  <Image width={81} height={85} src={_item.avatar} alt={_item.name} />
                  <Box className="fx-col ml12 ">
                    <Box className="fx-row ai-ct">
                      <Text className=" white fz500" fontSize={['16px', '16px', '18px', '18px']}>
                        {_item.name}
                      </Text>
                      <JobWrapper className="center">
                        <Text className="fz14 gray ">{_item.job}</Text>
                      </JobWrapper>
                    </Box>
                    <Text className="mt12 gray" fontSize={['12px', '12px', '14px', '14px']}>
                      {_item.desc}
                    </Text>
                  </Box>
                </CardWrapper>
              ))}
            </Grid>
          </Box>
        ))}
      </Box>
      <Footer />
    </ContentWrapper>
  )
}
