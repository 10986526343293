import React from 'react'
import { useLocation } from 'react-router-dom'
import { styled } from 'styled-components'
import { log } from 'console'
import { Text, Box, Image } from '../packages'
import { docs, xWhite, discordWhite, support } from '@/assets/images'
import { ContentWrapper, Row } from '@/components'

const FooterWrapper = styled(Box)`
  // position: fixed;
  width: 100%;
  height: 44px;
  bottom: 0;
`
export const Footer = () => {
  return (
    <FooterWrapper className="center" marginTop={['54px', '54px', '154px', '154px']}>
      <ContentWrapper>
        <Row className="">
          <Box className="fx-row ai-ct">
            <Text className="gray fw400 fz16">© </Text>
            <Text className="gray fw400 fz16 ml4">2024</Text>
          </Box>
          <Box className="line" />
          <Text className="gray fz16">rein@predx.ai</Text>
        </Row>
      </ContentWrapper>
    </FooterWrapper>
  )
}
