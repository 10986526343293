import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { ConfigProvider } from 'antd'
import reportWebVitals from './reportWebVitals'
import { AppRouter } from '@/router'
import 'highlight.js/styles/a11y-light.css'

if (process.env.REACT_APP_ENV === 'prod') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          // input
          colorBorder: '#191B23',
          colorError: '#DC2626',
          colorErrorBorderHover: '#DC2626'

          // input
        },
        components: {
          Table: {
            headerBg: '#fff',
            headerColor: '#232632',
            headerSplitColor: '#fff',
            borderColor: '#EBEDF2'
          },
          Input: {
            activeBg: '#fff',
            activeBorderColor: '#8E2CE3',
            hoverBg: '#fff',
            hoverBorderColor: '#8E2CE3'
          },
          Slider: {
            controlSize: 20,
            dotActiveBorderColor: '#8E2CE3',
            dotBorderColor: '#8E2CE3',
            dotSize: 20,
            handleActiveColor: '#8E2CE3',
            handleColor: '#8E2CE3',

            handleLineWidthHover: 2,
            handleSize: 20,
            handleSizeHover: 20,
            railBg: '#E1D0F9',
            railHoverBg: '#E1D0F9',
            railSize: 10,
            trackBg: '#8E2CE3',
            trackBgDisabled: '#E1D0F9',
            trackHoverBg: '#8E2CE3'
          }
        }
      }}
    >
      <AppRouter />
    </ConfigProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
