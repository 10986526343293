import styled, { DefaultTheme } from 'styled-components'
import { space, typography, layout } from 'styled-system'

import { TextProps } from './types'

const Text = styled.div<TextProps>`
  color: #202025;
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${({ ellipsis }) =>
    ellipsis &&
    `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}

  ${space}
  ${typography}
  ${layout}
  pointer-events: auto;
  ${({ small }) => small && `font-size: 14px;`}
`

Text.defaultProps = {
  color: 'red',
  small: false,
  fontSize: '14px',
  fontWeight: 400,
  ellipsis: false
}

export default Text
