import React, { FC } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { TooltipPlacement } from 'antd/es/tooltip'
import { Box, Image, Text, Grid } from '@/packages'
import { AppMaxWidth } from '@/config'

export const Divider = styled(Box)`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.04);
`
export const Vertical = styled(Box)<{ h: number }>`
  width: 1px;
  height: ${({ h }) => `${h}px`};
  border: 1px solid #2e2e2e;
`

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const Col = styled(Box)`
  display: flex;
  flex-direction: column;
`
export const ColorText = styled(Text)`
  background: linear-gradient(143deg, #8e2ce3 -4.18%, #4b00df 92.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`
export const Container = styled(Box)`
  max-width: ${AppMaxWidth};
  width: 100%;
`
interface iContentWrapper {
  children: React.ReactNode
}

export const ContentWrapper: React.FC<iContentWrapper> = ({ children }) => {
  return (
    <Box className="w100 fx jc-ct ">
      <Box
        className="w100"
        maxWidth="1040px"
        paddingLeft={['24px', '24px', '0px', '0px']}
        paddingRight={['24px', '24px', '0px', '0px']}
      >
        {children}
      </Box>
    </Box>
  )
}

interface iTooltip {
  children: React.ReactNode
  title: string
  color?: string
  placement?: TooltipPlacement
  trigger?: any
}
export const AppTooltip: React.FC<iTooltip> = ({ children, title, color, placement, trigger }) => {
  return (
    <Tooltip title={title} color={color || '#474747'} placement={placement || 'top'} trigger={trigger || 'hover'}>
      {children}
    </Tooltip>
  )
}

interface iListingWrapper {
  children: React.ReactNode
  onPageBottom?: () => void
  onContainerBottom: () => void
  height?: string
}
export const ListingLoadWrapper: React.FC<iListingWrapper> = ({
  children,
  onPageBottom,
  onContainerBottom,
  height
}) => {
  const handleOnDocumentBottom = () => {
    onPageBottom && onPageBottom()
  }
  const handleContainerOnBottom = () => {
    onContainerBottom()
  }
  useBottomScrollListener(handleOnDocumentBottom)
  const listRef: any = useBottomScrollListener(handleContainerOnBottom)
  return (
    <Box ref={listRef} className="hide_scrollbar" height={height || `710px`} style={{ overflow: 'auto' }}>
      {children}
    </Box>
  )
}

interface iLoadingItem {
  height: number
  mt: number
}
const LoadingLeft = styled(Box)<{ h: number }>`
  width: ${({ h }) => `${h * 0.5}px`};
  height: ${({ h }) => `${h * 0.5}px`};
  background: #2f3239;
  border-radius: 50%;
`
const LoadingMiddle = styled(Box)<{ h: string; w: string }>`
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  background: #2f3239;
`
const LoadingRight = styled(Box)<{ w: string }>`
  width: ${({ w }) => w};
  height: 24px;
  background: #2f3239;
`

export const LoadingItem: React.FC<iLoadingItem> = ({ height, mt }) => {
  return (
    <Row background="#1A1D23" width="100%" height={`${height}px`} px="14px" className="jc-sb" mt={`${mt}px`}>
      <LoadingLeft h={height} />
      <Col ml="10px" className="" style={{ width: '60%' }}>
        <LoadingMiddle h="16px" w="70%" />
        <LoadingMiddle h="12px" w="100%" mt="10px" />
      </Col>
      <LoadingRight w="15%" />
    </Row>
  )
}
export const LoadingItemWrapper: React.FC<{ count?: number; height: number; mt?: number }> = ({
  count,
  height,
  mt
}) => {
  return (
    <Box className="w100">
      {Array(count || 3)
        .fill('')
        .map((item: string) => (
          <LoadingItem key={Math.random()} height={height} mt={mt || 12} />
        ))}
    </Box>
  )
}

export const BgWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box background="rgba(255, 255, 255, 0.04)" borderRadius="12px" px="12px" py="12px">
      {children}
    </Box>
  )
}

interface iLoadingSkeleton {
  w: number[]
  h: number[]
  br: string
}
export const LoadingSkeleton: React.FC<iLoadingSkeleton> = ({ w, h, br }) => {
  return <Box height={h} width={w} borderRadius={br} className="loadingSkeletonAnimation" />
}

interface iPageTitle {
  title: string
  subTitle: string
}
export const PageTitle: FC<iPageTitle> = ({ title, subTitle }) => {
  return (
    <Box height={['80px', '80px', '160px', '160px']} className="fx-col jc-ct">
      <Text className="white fw500" fontSize={['24px', '32px', '36px', '48px']}>
        {title}
      </Text>
      <Text className="gray  mt24" fontSize={['18px', '18px', '24px', '24px']}>
        {subTitle}
      </Text>
    </Box>
  )
}

export const CardWrapper = styled(Box)<{ bg?: string; hover?: string }>`
  background: ${({ bg }) => bg || 'rgb(26, 26, 26)'};
  border: 1px solid #333;
  cursor: pointer;
  &:hover {
    background: ${({ hover }) => hover || 'rgb(51, 51, 51)'};
  }
`

export const GreenWrapper = styled(Box)`
  background: #9c88f8;
  height: 48px;
  width: 100%;
  border: 1px solid #9c88f8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #b0a2ff;
  }
`

export const LineTextLine: FC<{ name: string }> = ({ name }) => {
  return (
    <Grid className="fx-row ai-ct" gridTemplateColumns={['1.4fr 1fr 1.4fr']} gridGap={['12px']}>
      <Box background="#fff" height="1px" className="" />
      <Box className="fz12 white center">{name}</Box>
      <Box background="#fff" height="1px" />
    </Grid>
  )
}

// export const MobileMenu = () => {
//   return (
//     <div className='menu'>
//       <span></span>
//       <span></span>
//       <span></span>
//   </div>

//   )
// }
