import React, { useState, useEffect } from 'react'
import { styled } from 'styled-components'
import { Popup } from 'antd-mobile'
import { Text, Box } from '@/packages'
import { discord, bg, x, logo, doc, blog, menu, close } from '@/assets/images'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { Footer, LaunchApp } from '@/components'
import { TWITTER, DC, BLOG, DOCS } from '@/config'

const NavWrapper = styled(Box)``

export const DashboardNav = () => {
  const { isMobile, isTablet } = useMatchBreakpoints()

  const [open, setOpen] = useState<boolean>(false)

  const menuMobile = [
    { name: 'Docs', icon: doc, h: 28, w: 28, link: DOCS },
    { name: 'Blog', icon: blog, h: 22, w: 28, link: BLOG }
  ]

  const openWindow = (url: string) => {
    window.open(url)
  }
  return (
    <Box className=" w100 relative  h100">
      <Popup
        visible={open}
        onMaskClick={() => {
          setOpen(false)
        }}
        position="right"
        bodyStyle={{ width: '68vw' }}
      >
        <Box className="fx-col bd-box" padding={20}>
          <Box className="fx-row ai-ct jc-sb mb10">
            <img src={logo} style={{ height: 30, width: 88.8 }} alt="" />
            <img src={close} style={{ height: 25, width: 25 }} alt="" onClick={() => setOpen(false)} />
          </Box>
          <Box className="fx-col">
            {menuMobile.map((item) => (
              <Box className="fx-row ai-ct mt20" onClick={() => openWindow(item.link)}>
                <img src={item.icon} alt="" style={{ height: item.h, width: item.w }} />
                <Text className="gray3 fz14 fw400 ml12">{item.name}</Text>
              </Box>
            ))}
          </Box>
        </Box>
      </Popup>

      <NavWrapper
        paddingLeft={[16, 16, 32, 90]}
        paddingRight={[16, 16, 32, 90]}
        paddingTop={20}
        className="bd-box fx-row ai-ct jc-sb  w100 "
      >
        <Box height={[30, 35, 50, 50]} width={[88.8, 103.6, 148, 148]} padding={1} className="">
          <img src={logo} className="w100 h100" alt="" />
        </Box>
        {isMobile ? (
          <Box height={32} width={32} onClick={() => setOpen(true)}>
            <img src={menu} alt="" className="h100 w100" />
          </Box>
        ) : (
          <Box className="fx-row ai-ct jc-sb " width={[96, 156, 220, 326]}>
            <Text className="fw700 ffb dark click" fontSize={[12, 16, 18, 18]} onClick={() => openWindow(BLOG)}>
              Blog
            </Text>
            <Text className="fw700 ffb dark click" fontSize={[12, 16, 18, 18]} onClick={() => openWindow(DOCS)}>
              Docs
            </Text>
          </Box>
        )}
        <LaunchApp size="sm" />
      </NavWrapper>
    </Box>
  )
}
