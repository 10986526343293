import React, { useState, useEffect, FC } from 'react'
import { styled } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Text, Box, Image } from '@/packages'
import { arrowRight } from '@/assets/images'

const LaunchAppWrapper = styled(Box)<{ w: string; h: string }>`
  width: ${({ w }) => w};
  height: ${({ h }) => h};
  border-radius: 50px;
  background: linear-gradient(143deg, #8e2ce3 -4.18%, #4b00df 92.28%);
`

interface iLaunchApp {
  size: 'sm' | 'md'
}
export const LaunchApp: FC<iLaunchApp> = ({ size }) => {
  const navigate = useNavigate()

  return (
    <LaunchAppWrapper
      onClick={() => navigate('/trade')}
      className="fx-row center click"
      w={size === 'sm' ? '125px' : '278px'}
      h={size === 'sm' ? '34px' : '64px'}
    >
      <Text className="white fw400" fontSize={size === 'sm' ? [12] : [18]}>
        Launch App
      </Text>
      {size !== 'sm' && <Image src={arrowRight} height={16.2} width={21.6} className="ml4" alt="" />}
    </LaunchAppWrapper>
  )
}
