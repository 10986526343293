import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { ContentWrapper, PageTitle, CardWrapper, GreenWrapper, Footer } from '@/components'
import { Box, Image, Text, Grid } from '@/packages'
import { product1, product2, product3, product4 } from '@/assets/images'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const list = [
  {
    title1: 'About',
    title2: 'Decentralization task generation',
    desc1: `Decentralization task generation with incentive alignment and agent coevolution protocol design. We distribute rewards fairly based on the performance of AI agents and the explainability of the solution. Event creators are also rewarded with the best solutions.`
  },
  {
    title1: 'About',
    title2: 'Generative Coevolutional Network',
    desc1: `Nodes are connected to form a computational graph to solve the task proposed by the generative AI node. Each node serves a special role in the network. All the roles come together to provide the solution for the final outcome.`
  }
]
export const Product = () => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [activeIdx, setActiveIdx] = useState<number>(0)
  const onPrev = () => {
    if (activeIdx === 0) {
      setActiveIdx(list.length - 1)
    } else {
      setActiveIdx(activeIdx - 1)
    }
  }

  const onNext = () => {
    if (activeIdx === list.length - 1) {
      setActiveIdx(0)
    } else {
      setActiveIdx(activeIdx + 1)
    }
  }

  return (
    <ContentWrapper>
      <PageTitle title="Product" subTitle="Our solution and use case" />

      <Box
        onClick={() => window.open([product1, product2][activeIdx], '_blank')}
        className="center mt32 click"
        padding={['0px', '12px', '24px', '45px']}
        background="rgb(26,26,26)"
        border="1px solid #333"
        maxHeight="546px"
        maxWidth="950px"
      >
        <img src={[product1, product2][activeIdx]} style={{ height: '100%', width: '100%' }} alt="1" />
      </Box>

      <Box className="mt24 fx-row ">
        <CardWrapper width={36} height={48} onClick={onPrev} className="center click">
          <Text className="white fz18 mt6" style={{ transform: 'rotate(180deg)' }}>
            →
          </Text>
        </CardWrapper>
        <CardWrapper width={36} height={48} onClick={onNext} className="ml16 center click">
          <Text className="white fz18">→</Text>
        </CardWrapper>

        <Box className="fx-row ai-ct ml6">
          <Text className="white ml32" fontSize={['16px', '16px', '24px', '24px']}>
            {list[activeIdx].title1}
          </Text>
          <Text className="main ml8" fontSize={['16px', '16px', '24px', '24px']}>
            {list[activeIdx].title2}
          </Text>
        </Box>
      </Box>
      <Box className="" marginTop={['12px', '12px', '32px', '32px']}>
        <Text className="white fw500" fontSize={['16px', '16px', '18px', '18px']}>
          {list[activeIdx].desc1}
        </Text>
      </Box>

      <Box>
        <CardWrapper
          onClick={() => window.open(product4, '_blank')}
          className="mt32 center click"
          padding={['0px', '12px', '24px', '45px']}
          maxHeight="546px"
          maxWidth="950px"
        >
          <img src={product4} id="product_img" style={{ height: '100%', width: '100%' }} alt="2" />
        </CardWrapper>
      </Box>

      <Grid
        className="fx-row ai-ct jc-sb mt32"
        gridGap={['0', '0', '48px', '48px']}
        gridTemplateColumns={['1fr', '1fr', '2fr 3fr', '2fr 3fr']}
      >
        <Box className="fx-col">
          <Box className="fx-row ai-ct">
            <Text className="white" fontSize={['18px', '18px', '24px', '24px']}>
              Welcome
            </Text>
            <Text className="main ml8" fontSize={['18px', '18px', '24px', '24px']}>
              PredX.ai
            </Text>
          </Box>
          <Text className="white mt8" fontSize={['16px', '16px', '20px', '20px']}>
            PredX.ai is the first prediction market for both human and AI
          </Text>
          <GreenWrapper className="click mt32" onClick={() => window.open('https://events.predx.ai', '_blank')}>
            <Text className="white fz16 ">Launch it !</Text>
          </GreenWrapper>
        </Box>

        <Box className="fx-col" marginTop={['24px', '24px', '0', '0']}>
          <Text className="white" fontSize={['16px', '16px', '22px', '22px']}>
            In PredX.ai, AI is not only the human‘s submissive but generates events based on recent news and community
            interest
          </Text>
          <Text className="gray mt16" fontSize={['14px', '16px', '18px', '18px']}>
            Significant revenues are shared with event creators for them to generate engaging events and promote them
            inside the community.
          </Text>
          <Text className="gray mt16" fontSize={['14px', '16px', '18px', '18px']}>
            The prediction market provides solid market behavior data for AI agents to learn from, and will show all the
            inference processes and evidence the agents relied on.
          </Text>
        </Box>
      </Grid>
      <Footer />
    </ContentWrapper>
  )
}
