import React, { useState } from 'react'
import { styled } from 'styled-components'
import { ContentWrapper, PageTitle, CardWrapper, Footer } from '@/components'
import { Box, Image, Text, Grid } from '@/packages'
import {
  nft,
  writing1,
  writing2,
  writing3,
  writing4,
  writing5,
  writing6,
  writing7,
  writing8,
  writing9,
  writing10,
  writing11
} from '@/assets/images'

const list = [
  {
    title: "Shaping the Future of Prediction Markets with PredX's New Telegram Mini App",
    time: 'May 03, 2024',
    img: writing5,
    url: 'https://www.vcpost.com/articles/126236/20240503/shaping-the-future-of-prediction-markets-with-predxs-new-telegram-mini-app.htm'
  },
  {
    title: 'How AI Agents Supercharge Web3’s Micro Prediction Markets in 2024',
    time: 'May 01, 2024',
    img: writing6,
    url: 'https://bitcoinist.com/how-ai-agents-supercharge-web3s-micro-prediction-markets-in-2024'
  },
  {
    title: '预测市场基础：利用 AI 在微观层面上创建预测市场',
    time: 'Apr 26, 2024',
    img: writing10,
    url: 'https://foresightnews.pro/article/detail/59177'
  },
  {
    title: 'As Institutional ETPs Push BTC Higher, The Crypto Ecosystem Needs A Revival',
    time: 'Apr 25, 2024',
    img: writing7,
    url: 'https://www.forbes.com/sites/lawrencewintermeyer/2024/04/25/as-institutional-etps-push-btc-higher-the-crypto-ecosystem-needs-a-revival/?sh=68257f7d532d'
  },

  {
    title: 'AI creates amazing Prediction Markets w/ PredX | Blockchain Interviews',
    time: 'Apr 05, 2024',
    img: writing8,
    url: 'https://www.youtube.com/watch?v=Nf6ClFfO9Sg'
  },
  {
    title: 'PredX 如何通过人工智能和社区赋权彻底改变预测市场',
    time: 'Mar 21, 2024',
    img: writing11,
    url: 'https://mpost.io/zh-CN/how-predx-aims-to-revolutionize-the-prediction-market-with-ai-and-community-empowerment/'
  },
  {
    title: 'Enhancing Community-led Decisions via Prediction Markets: A Discussion With Dr. Rein Y. Wu of PredX',
    time: 'Mar 12, 2024',
    img: writing9,
    url: 'https://hackernoon.com/enhancing-community-led-decisions-via-prediction-markets-a-discussion-with-dr-rein-y-wu-of-predx'
  },

  {
    title: `‘We’re back!’: Inside NYC’s bullish crypto party scene, after Bitcoin hit new all-time $70K high`,
    time: 'Mar 19, 2024',
    img: writing4,
    url: 'https://nypost.com/2024/03/19/tech/were-back-inside-nycs-bullish-crypto-party-scene-after-bitcoin-hit-new-all-time-70k-high/amp/'
  },
  {
    title: 'Protocol Village: AI-Enabled Prediction Market PredX Launches Testnet on Sei Chain',
    time: 'Mar 06, 2024',
    img: writing3,
    url: 'https://www.coindesk.com/tech/2024/02/28/protocol-latest-tech-news-crypto-blockchain/amp/'
  },
  {
    title: 'AI-Enabled Prediction Market PredX Raises $500k in Pre-Seed Funding',
    time: 'Feb 28, 2024',
    img: writing2,
    url: 'https://markets.businessinsider.com/news/currencies/ai-enabled-prediction-market-predx-raises-500k-in-pre-seed-funding-1033113254'
  },
  {
    title: 'A decentralized prediction market with AI - a deeper integration of profitability and emotion',
    time: 'Nov 16, 2023',
    img: writing1,
    url: 'https://predxai.medium.com/a-decentralized-prediction-market-with-ai-a-deeper-integration-of-profitability-and-emotion-5b6c1a9bc914'
  }
]

export const Writing = () => {
  return (
    <ContentWrapper>
      <PageTitle title="Writing" subTitle="Works & publications from our team" />
      <Box className="mt48">
        <Grid className="wrap fx-row w100" gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr', '1fr 1fr']}>
          {list.map((item, idx) => (
            <CardWrapper
              className="fx-col click"
              style={{ objectFit: 'cover' }}
              marginTop={['12px', '12px', '0', '0']}
              paddingLeft={['0', '12px', '34px', '34px']}
              paddingRight={['0', '12px', '34px', '34px']}
              paddingTop={['12px', '12px', '34px', '34px']}
              paddingBottom={['12px', '12px', '24px', '24px']}
              onClick={() => window.open(item.url, '_blank')}
            >
              <img src={item.img} style={{ height: '284px', width: '100%' }} alt={item.title} />
              <Text className="mt12 white" fontSize={['16px', '16px', '18px', '18px']}>
                {item.title}
              </Text>
              <Text className="mt8 gray" fontSize={['14px', '14px', '18px', '18px']}>
                {item.time}
              </Text>
            </CardWrapper>
          ))}
        </Grid>
      </Box>
      <Footer />
    </ContentWrapper>
  )
}
